import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "create"
    }}>{`Create`}</h2>
    <p>{`To keep it as simple as possible we accept a GET request.`}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://api.anymessage.cloud/conference/initiate"
      }}>{`https://api.anymessage.cloud/conference/initiate`}</a></p>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`anyDir001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hz$o932`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`conferenceStart`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Start date and time  of the conference in utc time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2024-04-02 11:30:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`conferenceEnd`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`End date and time of the conference in utc time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2024-04-02 12:30:00`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`adminName`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`no`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the conference administrator`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John Doe`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h3 {...{
      "id": "features"
    }}>{`Features`}</h3>
    <h4 {...{
      "id": "admin-name"
    }}>{`Admin Name`}</h4>
    <p>{`If the adminName GET parameter is present in the API call, you will receive an
HTTP response with an administration link containing the name. When the
conference starts, the name field will be pre-populated with the chosen name.
The administrator can change the name at any time during the conference.`}</p>
    <h4 {...{
      "id": "conference-duration"
    }}>{`Conference Duration`}</h4>
    <p>{`The conference duration is managed by the HTTP GET parameters conferenceStart
and conferenceEnd. The default duration is 60 minutes. During this time the
Admin can join and manage the conference. If the conference time is exceeded,
the Admin can continue to manage ongoing conferences. The conference will not
be terminated by our system. However, it is no longer possible to create or
join the conference using the Admin link. `}</p>
    <h3 {...{
      "id": "request-examples"
    }}>{`Request examples`}</h3>
    <p>{`The following examples fulfill this scenario: `}</p>
    <ol>
      <li parentName="ol">{`You want to schedule a meeting for 2024-10-20 10:00:00`}</li>
      <li parentName="ol">{`Your admins name is John Doe`}</li>
      <li parentName="ol">{`AnyMessage provided you the following credentials username = anyDir001 and password = hz7r§j`}</li>
    </ol>
    <h4 {...{
      "id": "shell"
    }}>{`Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
curl -G -i \\
    --data-urlencode "username=anyDir001" \\
    --data-urlencode "password=hz7r§j" \\
    --data-urlencode "adminName=John Doe" \\
    --data-urlencode "conferenceStart=2024-10-20 10:00:00" \\
    https://api.anymessage.cloud/conference/initiate

`}</code></pre>
    <h4 {...{
      "id": "php"
    }}>{`PHP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php

$url = 'https://api.anymessage.cloud/conference/initiate?';

$username = 'anyDir001'; 
$password = 'hz7r§j'; 
$adminName = 'John Doe'; 
$conferenceStart = '2024-10-20 10:00:00'; 


$parameter = array(
    'username'=>$username,
    'password'=>$password,
    'adminName'=>$adminName,
    'conferenceStart'=>$conferenceStart,
);

$url .= http_build_query($parameter);
    

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);

$response = curl_exec($ch);

// Response evaluation 
if($response === false){
    var_dump(curl_error($ch));
}else{
    var_dump($response);
}
?>
`}</code></pre>
    <h3 {...{
      "id": "request-responses"
    }}>{`Request responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`You Receive a HTTP status code of 200 and a json formatted message containing`}</p>
    <ul>
      <li parentName="ul">{`roomId representing the meeting room name`}</li>
      <li parentName="ul">{`conferenceLinkAdmin a http link to join the meeting as admin `}</li>
      <li parentName="ul">{`conferenceLinkUser a http link to join the as user / participant`}</li>
      <li parentName="ul">{`validFrom a date time in utc where the admin can start the meeting`}</li>
      <li parentName="ul">{`validUntil a date time in utc until the admin can start the meeting`}</li>
    </ul>
    <p>{`A sample success response looks like this.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "roomId": "conf-f4c98b61-42d4-493b-8a58-5dc94a9b4254",
    "conferenceLinkAdmin": "https://meet.anymessage.cloud/conf-f4c98b61-42d4-493b-8a58-5dc94a9b4254?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2RlcmF0b3IiOnRydWUsImF1ZCI6ImFueW1lc3NhZ2UiLCJpc3MiOiJhbnltZXNzYWdlIiwic3ViIjoibWVldC5hbnltZXNzYWdlLmNsb3VkIiwicm9vbSI6ImNvbmYtZjRjOThiNjEtNDJkNC00OTNiLThhNTgtNWRjOTRhOWI0MjU0IiwiY29udGV4dCI6eyJ1c2VyIjp7Im5hbWUiOiJKb2huIERvZSIsImxvYmJ5X2J5cGFzcyI6dHJ1ZX0sInJvb20iOnsibG9iYnkiOnRydWV9fSwibmJmIjoxNzI5NDEwODQwLCJleHAiOjE3Mjk0MTUxNjAsImlhdCI6MTcxMzc4MjU1NH0.Q8JDJfZ6eMoOAlSiFh2ouYqApXolXMpRkDy53NBGgLc",
    "conferenceLinkUser": "https://meet.anymessage.cloud/conf-f4c98b61-42d4-493b-8a58-5dc94a9b4254",
    "validFrom": "2024-10-20 09:54:00",
    "validUntil": "2024-10-20 11:06:00"
}

`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <pre><code parentName="pre" {...{}}>{`| HTTP status Code | Http response                                   | description                                             |
| ---------------- | ----------------------------------------------- | ------------------------------------------------------- |
| 401              | {"message":"Invalid credentials"}               | The provided credentials are not valid                  |
| 403              | {"message":"Service not granted"}               | The provided credentials are not valid for this service |
| 500              | {"message":"Invalid conferenceStart parameter"} | The conferenceStart is invalid.                         |
| 500              | {"message":"Internal Error"}                    | An internal error occurred                              |
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      